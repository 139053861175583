import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { BEURO__H, BEURO__Icon } from 'styleguide/packages/components/index.js'
import { toggleOpen, toggleClose, classes } from 'styleguide/packages/util/index.js'

const Toggle = props => {
  const { onClick, id, toggle, toggleCondition = true, className = '', children } = props

  // onClick is the custom functionality; toggle is the default open/close functionality
  const handleToggle = () => {
    onClick && onClick()

    toggleCondition && toggle && toggle(id)
  }

  return (
    <button type="button" className={`portal__toggle ${className}`} onClick={handleToggle}>
      {children}
    </button>
  )
}

/**
 * Custom Portal - Includes full size portal, partial, dialogue, and sidebar (left/right)
 * @constructor
 * @param {function} onClose - Passes any additional functinality you may need when the portal is closed
 * @param {string} className
 * @param {string} id - A crucial part of the portal, this id must be the same as the toggle id so that the portal knows when to open
 * @param {component} header - Pass with a custom header component
 * @param {component} footer - Pass with a custom footer component
 * @param {string} type - type of Portal
 * @param {component} children
 */
const Portal = props => {
  const {
    id = '',
    className = '',
    header = false,
    customHeader = false,
    footer = false,
    children,
    onClose,
    type,
    zIndex = 900,
  } = props
  const { ariaLabel = 'portal', ariaDescription = 'portal' } = props // accessibility
  const [isReady, _setIsReady] = useState(false)

  const classNames = classes([
    'portal__background',
    className,
    {
      '--dialogue': /dialogue/.test(type),
      '--partial': /partial/.test(type),
      '--full': /full/.test(type),
      '--sidebar': /sidebar/.test(type),
      '--sidebar-left': /sidebar-left/.test(type),
    },
  ])

  const handleCloseToggle = () => {
    onClose && onClose()

    toggleClose(id)
  }

  // Render the content AFTER the portal has transitioned to open
  useEffect(() => {
    const callback = mutationsList => {
      mutationsList.forEach(mutation => {
        if (mutation.attributeName === 'class') {
          _setIsReady(true)
          mutationObserver.disconnect()
        }
      })
    }
    const mutationObserver = new MutationObserver(callback)
    mutationObserver.observe(document.getElementById(id), { attributes: true })

    return () => mutationObserver.disconnect()
  }, [])

  return (
    <div id={id} className={classNames} onClick={() => toggleClose(id)} style={{ zIndex: zIndex }}>
      <div
        className={`portal__container ${className}__container`}
        role="dialog"
        aria-labelledby={ariaLabel}
        aria-describedby={ariaDescription}
        onClick={e => e.stopPropagation()}>
        <div className={`portal__wrapper ${className}__wrapper`}>
          {(header || customHeader) && (
            <div className={`portal__container--header ${className}__container--header`}>
              <div className={`portal__wrapper--header ${className}__wrapper--header`}>
                {customHeader}

                {header && (
                  <BEURO__H.SubsectionHeader
                    className={`portal__wrapper--header--content ${className}__wrapper--header--content`}>
                    {header}
                  </BEURO__H.SubsectionHeader>
                )}
              </div>
            </div>
          )}

          <Toggle {...props} onClick={handleCloseToggle}>
            <BEURO__Icon name="close" height="24" width="24" />
          </Toggle>

          <article className={`portal__content ${className}__content`}>
            {isReady && children}
          </article>

          {footer && <div className={`portal__footer ${className}__footer`}>{footer()}</div>}
        </div>
      </div>
    </div>
  )
}

Toggle.propTypes = {
  id: PropTypes.string.isRequired,
}

Portal.propTypes = {
  id: PropTypes.string.isRequired,
}

Portal.Dialogue = props => <Portal {...props} type="dialogue" />
Portal.Partial = props => <Portal {...props} type="partial" />
Portal.Full = props => <Portal {...props} type="full" />

// base sidebar defaults to the right side of the viewport
Portal.Sidebar = props => <Portal {...props} type="sidebar" />
Portal.LeftSidebar = props => <Portal {...props} type="sidebar-left" />

Portal.ToggleOpen = props => <Toggle {...props} toggle={toggleOpen} />
Portal.ToggleClose = props => <Toggle {...props} toggle={toggleClose} />

export default Portal
