import React from 'react'
import { BaseH } from './index'

const BEURO__H = props => <BaseH {...props} h={props.h} className={`${props.className}`} />

BEURO__H.Hero = props => (
  <BEURO__H {...props} h={props.h || 1} className={`${props.className || ''} BEURO__hero`} />
)
BEURO__H.SectionHeader = props => (
  <BEURO__H
    {...props}
    h={props.h || 2}
    className={`${props.className || ''} BEURO__section-header`}
  />
)
BEURO__H.SubsectionHeader = props => (
  <BEURO__H
    {...props}
    h={props.h || 3}
    className={`${props.className || ''} BEURO__subsection-header`}
  />
)
BEURO__H.Overline = props => (
  <BEURO__H {...props} h={props.h || 4} className={`${props.className || ''} BEURO__overline`} />
)

export default BEURO__H
