import { db } from 'services/config'
import { AEDIT_ANALYTICS_URL } from 'services/constants'
import { trackError } from 'services/util/error'

const ACTIONS = {
  pageView: 'page_view',
  requestConsultationClicked: 'request_consultation_clicked',
  providerBeforeAfterLoaded: 'provider_before_after_loaded',
}

const calls = new Map([
  [
    ACTIONS.pageView,
    {
      staticParams: {
        category: 'public_website',
        action: 'page_view',
      },
      callParamsLookup: {
        entityId: 'entity_id',
        entityType: 'entity_type',
        label: 'label',
        value: 'value',
        userId: 'user_id',
      },
    },
  ],
  [
    ACTIONS.requestConsultationClicked,
    {
      staticParams: {
        category: 'public_website',
        action: 'request_consultation_clicked',
        entity_type: 'provider',
        label: 'display_name',
      },
      // keys are param names for the function, values are the associated param
      // names for the db
      callParamsLookup: {
        entityId: 'entity_id',
        value: 'value',
        userId: 'user_id',
      },
    },
  ],
  [
    ACTIONS.providerBeforeAfterLoaded,
    {
      staticParams: {
        category: 'public_website',
        action: 'provider_before_after_loaded',
        entity_type: 'procedure_before_afters',
        label: 'display_name',
      },
      callParamsLookup: {
        entityId: 'entity_id',
        value: 'value',
        userId: 'user_id',
      },
    },
  ],
])

const aeditTrack = (eventTitle, callParams) => {
  // make sure the event exists
  if (!calls.has(eventTitle)) {
    return Promise.reject(`tracking event ${eventTitle} is not defined in aeditAnalytics.js`)
  }

  // get properties of the event
  const {
    staticParams: { endpoint = AEDIT_ANALYTICS_URL, ...staticParams },
    callParamsLookup,
  } = calls.get(eventTitle)
  // dupe out db params except endpoint
  let finalParams = { ...staticParams }

  let error = []
  // check that no call params are missing
  Object.keys(callParamsLookup).forEach(expected => {
    if (callParams[expected] === undefined) {
      error.push(`missing call parameter ${expected}`)
    }
  })
  // for every parameter in callParams,
  Object.entries(callParams).forEach(([cpName, cpValue]) => {
    // 1. check that we expected this parameter
    // 2. get the name for this parameter that the database expects
    const finalPropertyName = callParamsLookup[cpName]
    if (finalPropertyName === undefined) {
      error.push(`parameter ${cpName} wasn't expected`)
    }
    // 3. add it to the final parameters
    finalParams[finalPropertyName] = cpValue
  })

  // made it out without rejecting, so fire
  if (error.length > 0) {
    return Promise.reject(error.join(', ') + `-- in aeditTrack(${eventTitle})`)
  }
  return db
    .post(endpoint, { ...finalParams })
    .catch(err => trackError(`Failed to track event in aeditTrack`, err))
}

export { ACTIONS }
export default aeditTrack
