import React, { useEffect, useState } from 'react'
import { getBeforeAfterAltText } from 'services/util/image'
import ProviderInformation from './components/provider_information'
import SensitiveContentBlur from 'components/sensitive_content_blur'
import Img from 'components/img/cloudinary-img'
import useWindowSize from 'hooks/use_window_size'
import { Track } from 'services/analytics'
import { Portal, Spinner } from 'styleguide/packages/components'
import styles from './index.scss'

const SelectedImage = ({ selectedImage, isLoadingSelected }) => {
  return (
    <div className={`selected-image --${selectedImage.meta.orientation}`}>
      <SensitiveContentBlur isExplicit={Boolean(Number(selectedImage.meta.explicit))}>
        <div className="image-wrapper">
          {isLoadingSelected ? (
            <div className="spinner-wrapper">
              <Spinner />
            </div>
          ) : (
            <>
              <Img
                publicId={selectedImage?.before_image?.url}
                alt={getBeforeAfterAltText(selectedImage, 'before')}
              />
              <Img
                publicId={selectedImage?.after_images[0]?.url}
                alt={getBeforeAfterAltText(selectedImage, 'after')}
              />
            </>
          )}
        </div>
      </SensitiveContentBlur>
    </div>
  )
}

export const BAProviderModal = props => {
  const { provider, beforeAndAfters = [], procedure = {}, procedureName, geoLocationObj } = props
  const [selectedImage, setSelectedImage] = useState(null)
  const [isLoadingSelected, setIsLoadingSelected] = useState(false)

  const windowSize = useWindowSize()

  useEffect(() => {
    if (procedure.name) {
      const trackData = {
        procedure_id: procedure.id,
        procedure_name: procedure.name,
        provider_id: provider.id,
        provider_name: provider.name,
      }
      Track.providerBeforeAfterDetailLoaded(trackData)

      setSelectedImage({ index: 0, ...beforeAndAfters[0] })
    }
  }, [procedure])

  const swapImage = (photo, i) => {
    setIsLoadingSelected(true)

    const newSelectedImage = { index: i, ...photo }
    setSelectedImage(newSelectedImage)
    setTimeout(() => setIsLoadingSelected(false), 200)
  }

  const portalContent = photo => {
    return (
      <>
        <SelectedImage selectedImage={photo} isLoadingSelected={isLoadingSelected} />

        <ProviderInformation
          provider={provider}
          procedure={procedure}
          procedureName={procedureName}
          windowSize={windowSize}
          photoAlbum={beforeAndAfters}
          selectedImage={photo}
          setSelectedImage={setSelectedImage}
          swapImage={swapImage}
        />
      </>
    )
  }

  return (
    <>
      <Portal.Full id="beuro-ba-modal--provider">
        {!selectedImage ? (
          <div>
            <Spinner />
          </div>
        ) : windowSize.width > 768 ? (
          portalContent(selectedImage)
        ) : (
          beforeAndAfters?.map((photo, i) => {
            const photoObject = { index: i, ...photo }

            return portalContent(photoObject)
          })
        )}
      </Portal.Full>

      <style jsx>{styles}</style>
    </>
  )
}
