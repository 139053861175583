import React from 'react'
import { Portal } from 'styleguide/packages/components'
import styles from './index.scss'

export const VettingProcessModal = ({ id = 'vetting-process' }) => {
  return (
    <>
      <Portal.Dialogue id={id}>
        <div>
          <h4 className="BEURO__subsection-header text-center mb-2">
            Our Provider Vetting Process
          </h4>
          <aside className="mb-2">
            <p className="BEURO__body">
              AEDIT eliminates the guesswork in finding a provider by only endorsing accredited
              doctors and beauty professionals who not only have the experience, but the respect of
              their peers in their field. Contact us to find out more about AEDIT's screening
              process and how AEDIT vets our provider network.
            </p>
          </aside>
        </div>
      </Portal.Dialogue>
      <style jsx>{styles}</style>
    </>
  )
}
