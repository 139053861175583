import { useContext } from 'react'
import { GlobalContext } from 'components/global'
import { Btn } from 'styleguide/packages/components'
import styles from './styles.scss'
import SvgIcon from 'components/icons'

const SensitiveContentBlur = ({
  children,
  className = '',
  isExplicit,
  onClick,
  toggleCondition = true,
}) => {
  const { shouldBlurExplicitContent, setGlobalState } = useContext(GlobalContext)
  const blurred = shouldBlurExplicitContent && isExplicit
  return (
    <div className={`blur-wrapper ${className}`} onClick={onClick}>
      {blurred && (
        <div className="blur-wrapper-controls">
          <h3 className="controls-text mb-1">
            <SvgIcon name="eyeOff" className="mr-1" stroke="#fff" /> Sensitive Content
          </h3>
          <p className="BEURO__body BEURO__body--small mb-3 controls-text hide-md-only">
            This photo contains nudity. You must be at least 18 years of age to continue.
          </p>

          <Btn.Light
            className="view-content-btn"
            isSmall
            type="button"
            content={
              <>
                <span className="hidden-md">Click to</span> View
              </>
            }
            onClick={() => toggleCondition && setGlobalState({ shouldBlurExplicitContent: false })}
          />
        </div>
      )}

      <div className={blurred ? 'blurred-content' : ''}>{children}</div>
      <style jsx>{styles}</style>
    </div>
  )
}

export default SensitiveContentBlur
