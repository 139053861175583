import React from 'react'
import { BEURO__Btn, Portal } from 'styleguide/packages/components'
import { toggleClose } from 'styleguide/packages/util'
import Markdown from 'components/markdown'
import AeditLink from 'services/link'
import styles from './index.scss'

export const EvidenceBasedModal = ({ id = 'evidence-based-modal', content }) => {
  if (!content) return null
  return (
    <>
      <Portal.Dialogue id={id}>
        <Markdown className="eb-content-container markdown-content --beuro" content={content} />

        <AeditLink pathname="/editorial-process" className="ep-page-link">
          <BEURO__Btn.Gold
            className="ep-link-btn"
            content="Learn about our editorial process"
            onClick={() => toggleClose(id)}
          />
        </AeditLink>
      </Portal.Dialogue>
      <style jsx>{styles}</style>
    </>
  )
}
