import React from 'react'

const BaseH = props => {
  const { className = '', children, h, ...rest } = props

  const HTag = `h${h}`

  return (
    <HTag className={className} {...rest}>
      {children}
    </HTag>
  )
}

export { BaseH }
