import React from 'react'
import { Portal } from 'styleguide/packages/components'
import styles from './index.scss'

export const AeditBenefitsModal = ({ id = 'aedit-benefits' }) => {
  return (
    <>
      <Portal.Dialogue id={id}>
        <h4 className="BEURO__subsection-header text-center mb-2">AEDIT Booking Benefit</h4>
        <aside className="procedure-costs-header mb-2">
          <p className="BEURO__body text-center">
            Recieve up to $500 towards the cost of your procedure(s) with AEDIT
          </p>
        </aside>
        <aside className="procedure-costs">
          <div>
            <p className="BEURO__body BEURO__body--small">Procedure(s) cost</p>
            <p className="BEURO__body BEURO__body--small">Booking Benefit value</p>
          </div>

          <div>
            <p className="BEURO__body">$250-$999</p>
            <p className="BEURO__body">$25</p>
          </div>

          <div>
            <p className="BEURO__body">$1000-$2999</p>
            <p className="BEURO__body">$50</p>
          </div>

          <div>
            <p className="BEURO__body">$3000-$4999</p>
            <p className="BEURO__body">$150</p>
          </div>

          <div>
            <p className="BEURO__body">$5000-$9999</p>
            <p className="BEURO__body">$250</p>
          </div>

          <div>
            <p className="BEURO__body">$10,000 and up</p>
            <p className="BEURO__body">$500</p>
          </div>
        </aside>
      </Portal.Dialogue>

      <style jsx>{styles}</style>
    </>
  )
}
