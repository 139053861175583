import React, { useContext } from 'react'
import { ConsultationModal } from 'components/modals'
import { SUBSCRIPTION_SOURCES } from 'services/util/payment'
import { BEURO__Btn } from 'styleguide/packages/components'
import { GlobalContext } from 'components/global'
import { showPortal } from 'components/portal'
import aeditTrack, { ACTIONS } from 'services/aeditAnalytics'
import { Track } from 'services/analytics'
import useAuthRequired from 'hooks/use_auth_required'
import cn from 'classnames'

export const ConsultationButton = ({
  content = 'Book Now',
  className = '',
  provider,
  trackData = {},
}) => {
  const { currentUser } = useContext(GlobalContext)

  const userId = currentUser?.id ?? null
  const currentUserType = currentUser?._type
  const instantBooking =
    Boolean(provider.allow_instant_booking) && Boolean(provider.instant_booking_enabled)

  const handleClick = useAuthRequired({
    postAuth: () => {
      Track.bookingFlowOpened(trackData)
      showPortal(ConsultationModal, { provider })

      // should probably move this to fire on mount in the consultation portal itself
      Track.providerConsultationStep({
        id: provider.id,
        first_name: provider.first_name,
        last_name: provider.last_name,
        is_instant_booking: instantBooking,
        step: 1,
      })

      aeditTrack(ACTIONS.requestConsultationClicked, {
        entityId: provider.id,
        value: `${provider.first_name} ${provider.last_name}`,
        userId,
      })
    },
    analytics: trackData,
    source: SUBSCRIPTION_SOURCES.VOUCHERS,
  })

  // Move this to the top layer when we move consultation flow
  // const hasProcedures = Boolean(provider?.procedures?.length)
  // const bookingIsAvailable = Boolean(provider.booking_available)
  // if (!hasProcedures || !bookingIsAvailable) {
  //   return null
  // }

  return (
    <BEURO__Btn.Gold
      data-cy="consultation-button"
      className={cn('consultation-button', className)}
      onClick={() => handleClick()}
      content={currentUserType === 'provider' ? 'Sign up as a candidate' : content}
    />
  )
}
