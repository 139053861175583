import dynamic from 'next/dynamic'

export { VettingProcessModal } from './vetting_process'
export { AeditBenefitsModal } from './aedit_benefits'
export { BAProviderModal } from './ba_provider'
export { EvidenceBasedModal } from './evidence_based'

export const VirtualConsultationWaitingRoomExit = dynamic(() =>
  import('./virtual_consultation_waiting_room_exit')
)
export const VirtualConsultationWaitingRoomJoin = dynamic(() =>
  import('./virtual_consultation_waiting_room_join')
)
export const VirtualConsultationClose = dynamic(() => import('./virtual_consultation_close'))

export const ProcedureConfirmationModal = dynamic(() => import('./procedure_confirmation'))
export const ProviderGalleryModal = dynamic(() => import('./provider_gallery'))
export const TryOn3DModal = dynamic(() => import('./try_on_3d'))
export const VirtualAppointmentHelpModal = dynamic(() => import('./virtual_appointment_help'))
export const ConsultationModal = dynamic(() => import('./consultation'))
export const ConsultationExitModal = dynamic(() => import('./consultation/consultation_exit'))

export const PostAuthProTransitionModal = dynamic(() => import('./post_auth_pro_transition'))
export const ProPurchaseSuccessModal = dynamic(() => import('./pro/purchase_success'))
export const ProPaywallModal = dynamic(() => import('./pro_paywall'))
export const ProPlanConfirmationModal = dynamic(() => import('./pro_plan_confirmation'))

export const TransactionsModal = dynamic(() => import('./transactions'))
